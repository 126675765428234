import { cilCheckAlt, cilFilter, cilReload, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
    CButton,
    CCard,
    CCardBody,
    CCollapse,
    CContainer,
    CForm,
    CFormCheck,
    CFormInput,
    CTooltip,
} from '@coreui/react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { generateAppointmentCheckinData, generateCode, updateUserByNumber } from '@/api/appointment/AppointmentCalls';
import { getUserMappings } from '@/api/dcprofessional/DcProfessionalCalls';
import { getPatients } from '@/api/patient/PatientCalls';
import { getFormTemplates } from '@/api/professional/ProfessionalCalls';

import Alert from '@/components/Alert';
import EternoSpinner from '@/components/EternoLogoSpinner/EternoSpinner';
import AppointmentCodeModal from '@/components/TodaysAppointments/AppointmentCodeModal';
import AppointmentDetails from '@/components/TodaysAppointments/AppointmentDetails';
import AppointmentEntry from '@/components/TodaysAppointments/AppointmentEntry';
import { AppointmentStatus } from '@/components/TodaysAppointments/AppointmentStatusTag';
import CertModal from '@/components/TodaysAppointments/CertModal';
import GlobalManualCheckinModal from '@/components/TodaysAppointments/GlobalManualCheckinModal';
import LegacyCheckinModal from '@/components/TodaysAppointments/LegacyCheckinModal';
import LegacyManualCheckinModal from '@/components/TodaysAppointments/LegacyManualCheckinModal';
import LoadingIndicator from '@/components/TodaysAppointments/LoadingIndicator';
import ManualCheckinModal from '@/components/TodaysAppointments/ManualCheckinModal';
import PrinterDropdown from '@/components/TodaysAppointments/PrinterDropdown';

import AWSContext from '@/context/AWSContext';
import AppointmentContext from '@/context/appointment/AppointmentContext';
import { useTheme } from '@/context/theme/ThemeContext';

import { UserStatus } from '@/utils/constants';
import {
    FeatureKey,
    getExtAuthToken,
    getUnknownAppointments,
    printPcsTicket,
    syncPatientWithId,
    updatePcs,
} from '@/utils/helpers';

import {
    useAdminConfigStore,
    useLocationStore,
    usePatientCallSystemStore,
    usePowerUserStore,
    usePrismicStore,
    useTodaysAppointmentsStore,
} from '@/zustandStore';

import FailedFallback from '../pages/failed/FailedFallback';
import LoadingPage from '../pages/loading/LoadingPage';

const TodaysAppointments = () => {
    const { userToken } = useContext(AWSContext);
    const { t } = useTranslation();
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showUnknownAppointments, setShowUnknownAppointments] = useState(true);
    const [showPastAppointments, setShowPastAppointments] = useState(false);
    const [showAppointmentsWithoutProfessional, setShowAppointmentsWithoutProfessional] = useState(false);
    const [manualCheckinPatientName, setManualCheckinPatientName] = useState('');
    const [syncUserInput, setSyncUserInput] = useState('');
    const [showSyncSection, setShowSyncSection] = useState(false);
    const [showFilterSection, setShowFilterSection] = useState(false);
    const [professionalsFilter, setProfessionalsFilter] = useState({});
    const [filteredSyncSearch, setFilteredSyncSearch] = useState([]);
    const [showSyncSearchResults, setShowSyncSearchResults] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);
    const [isListBeingClicked, setIsListBeingClicked] = useState(false);
    const [selectedSyncUser, setSelectedSyncUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const showLegacyCheckinModal = useTodaysAppointmentsStore((state) => state.showLegacyCheckinModal);
    const showNewManualCheckinModal = useTodaysAppointmentsStore((state) => state.showNewManualCheckinModal);
    const showLegacyManualCheckinModal = useTodaysAppointmentsStore((state) => state.showLegacyManualCheckinModal);
    const showGlobalManualCheckinModal = useTodaysAppointmentsStore((state) => state.showGlobalManualCheckinModal);
    const savedTodaysAppointments = useTodaysAppointmentsStore((state) => state.todaysAppointments);
    const unknownAppointments = useTodaysAppointmentsStore((state) => state.unknownAppointments);
    const professionalMappings = useTodaysAppointmentsStore((state) => state.professionalMappings);
    const professionals = usePrismicStore((state) => state.professionals);
    const patients = useTodaysAppointmentsStore((state) => state.patients);
    const hasFailed = useTodaysAppointmentsStore((state) => state.hasFailed);
    const checkinData = useTodaysAppointmentsStore((state) => state.checkinData);
    const prismicData = usePrismicStore((state) => state.todaysAppointmentsPrismicData);
    const syncSearchUserData = useTodaysAppointmentsStore((state) => state.syncSearchUserData);
    const appointmentTicketNumber = useTodaysAppointmentsStore((state) => state.appointmentTicketNumber);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const selectedPrinter = usePatientCallSystemStore((state) => state.selectedPrinter);
    const userGroups = usePrismicStore((state) => state.userGroups);
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);
    const { getAllTodaysAppointments, todaysAppointments, error, setToDefault, successMessage } =
        useContext(AppointmentContext);
    const inputRef = useRef();
    const { colors } = useTheme();

    const filteredAndSortedProfessionals = useMemo(() => {
        if (!professionals || !professionalMappings) return [];

        return Object.keys(professionals)
            .filter(
                (key) =>
                    professionalMappings[key]?.dc_instance_id === selectedLocation.instanceId &&
                    professionals[key].location_hub.id === selectedLocation.config.hub.id
            )
            .sort((a, b) => professionals[a].display_name[0].text.localeCompare(professionals[b].display_name[0].text));
    }, [professionals, professionalMappings, selectedLocation]);

    const updateSelectedAppointment = (item) => {
        const { professionalFormTemplates, isCheckinDataLoading, isPatientDataLoading } =
            useTodaysAppointmentsStore.getState();

        if (detailsLoading || !professionalFormTemplates || isCheckinDataLoading || isPatientDataLoading) return;

        if (item.id === selectedAppointment?.id) {
            setSelectedAppointment(null);
        } else {
            setSelectedAppointment(item);
            setDetailsLoading(true);
        }
    };

    const updateProfessionalsFilter = (professionalKey) => {
        const newProfessionalsFilter = { ...professionalsFilter };

        if (newProfessionalsFilter[professionalKey]) {
            delete newProfessionalsFilter[professionalKey];
        } else {
            newProfessionalsFilter[professionalKey] = true;
        }

        setProfessionalsFilter(newProfessionalsFilter);
    };

    const loadAuthToken = async () => {
        let { authToken } = usePatientCallSystemStore.getState();

        if (
            !authToken?.token ||
            authToken.instanceId !== selectedLocation.instanceId ||
            Date.now() > authToken.validUntil
        ) {
            console.log('No saved or outdated auth token found.');
            console.log('[START] GET - Ext Auth Token');
            const newToken = await getExtAuthToken(userToken, selectedLocation.instanceId);
            console.log('[END] GET - Ext Auth Token');
            if (!newToken) {
                console.log('Retrieving new token failed.');
                return null;
            }

            authToken = {
                token: newToken,
                validUntil: Date.now() + 3600000,
                instanceId: selectedLocation.instanceId,
            };

            usePatientCallSystemStore.setState({ authToken });
            console.log({ authToken });
            console.log('Saved new token in localstorage');
        }

        if (!authToken?.token) {
            console.log('Could not find or create new token.');
            return null;
        }

        if (userGroups?.admin) {
            usePowerUserStore.setState({
                lastUsedCert:
                    selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.certificate[0].text,
            });
        }

        return authToken;
    };

    const syncPatient = async () => {
        setSyncLoading(true);
        const isSuccessful = await syncPatientWithId(userToken, selectedLocation.instanceId, selectedSyncUser);

        if (isSuccessful) {
            toast.success('Patient wurde sychronisiert!', {
                iconTheme: {
                    primary: colors.secondary,
                    secondary: '#fff',
                },
            });
        }

        setShowSyncSection(false);
        setSyncLoading(false);
    };

    const prepareGenerateCodeBody = (selectedForms, appointmentId = null, selectedProfessional = null) => {
        const getUserId = () => {
            if (selectedAppointment && !appointmentId) {
                if (selectedAppointment.user_id && selectedAppointment.user_id !== '-') {
                    return selectedAppointment.user_id;
                }
                return selectedAppointment.links.patient_id ?? '-';
            }
            return '-';
        };

        return {
            forms: selectedForms ?? [],
            location: {
                location_key: selectedLocation.key,
            },
            user: {
                user_id: getUserId(),
            },
            professional: {
                professional_id:
                    selectedAppointment && !selectedProfessional
                        ? selectedAppointment.professional.id
                        : professionalMappings[selectedProfessional].user_id,
                professional_key:
                    selectedAppointment && !selectedProfessional
                        ? selectedAppointment.professional.key
                        : professionalMappings[selectedProfessional].prismic_key,
            },
            appointment_id: appointmentId ?? selectedAppointment.id,
        };
    };

    const updateCheckin = async (status, ticket = null, selectedProfessional = null) => {
        const patientName = selectedAppointment.user_id ? patients[selectedAppointment.user_id]?.name : '';

        const data = {
            group: selectedLocation.key,
            number: ticket ? String(ticket) : appointmentTicketNumber[selectedAppointment.id],
            name:
                selectedAppointment.data.patient_name ||
                (patientName?.first ? `${patientName.first} ${patientName.last}` : manualCheckinPatientName),
            user_id:
                selectedAppointment.user_id && selectedAppointment.user_id !== '-'
                    ? selectedAppointment.user_id
                    : selectedAppointment.data.generated_id,
            location_key: selectedLocation.key,
            appointment: {
                id: selectedAppointment.id,
                checkin_status: status,
                doctor_name: selectedProfessional ?? '',
            },
            instance_id: selectedLocation.instanceId,
        };

        const response = await updateUserByNumber(userToken, data);

        if (!response.data) return;

        useTodaysAppointmentsStore.setState({
            checkinData: { ...checkinData, [selectedAppointment.id]: response.data },
        });
    };

    const confirmCheckinForUnknownAppointment = async () => {
        const data = {
            group: selectedLocation.key,
            number: checkinData[selectedAppointment.id].number,
            name: checkinData[selectedAppointment.id].name,
            user_id: '-',
            location_key: selectedLocation.key,
            user_status: UserStatus.UNKNOWN,
            appointment: {
                id: selectedAppointment.id,
                checkin_status: AppointmentStatus.MANUALLY_CHECKED_IN,
                doctor_name: checkinData[selectedAppointment.id].appointment.doctor_name,
            },
            instance_id: selectedLocation.instanceId,
        };

        const response = await updateUserByNumber(userToken, data);

        if (!response.data) return;

        useTodaysAppointmentsStore.setState({
            checkinData: { ...checkinData, [selectedAppointment.id]: response.data },
        });
    };

    const confirmCheckin = async () => {
        if (!checkinData[selectedAppointment.id]?.number && !appointmentTicketNumber[selectedAppointment.id]) return;

        setDetailsLoading(true);

        let authToken;
        if (selectedLocation.config.is_new_checkin_available) {
            authToken = await loadAuthToken();

            if (!authToken) {
                setDetailsLoading(false);
                return;
            }
        }

        const isManualCheckin = !selectedAppointment.data?.patient_name || !patients[selectedAppointment.user_id]?.name;

        if (selectedAppointment.user_status === UserStatus.UNKNOWN) {
            await confirmCheckinForUnknownAppointment();
            setDetailsLoading(false);
            return;
        }

        if (selectedLocation.config.is_new_checkin_available) {
            await updatePcs(
                authToken.token,
                selectedPrinter[selectedLocation.key]?.address[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.address[0].text ??
                    selectedLocation.config.patient_call_system_ip[0].text,
                selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.certificate[0].text,
                professionalMappings[selectedAppointment.professional.key]?.pcs_stack_id || 'zimmer01',
                checkinData[selectedAppointment.id]?.number ?? appointmentTicketNumber[selectedAppointment.id]
            );
        }

        await updateCheckin(
            isManualCheckin ? AppointmentStatus.MANUALLY_CHECKED_IN : AppointmentStatus.CHECKED_IN,
            checkinData[selectedAppointment.id]?.number ?? appointmentTicketNumber[selectedAppointment.id],
            checkinData[selectedAppointment.id].appointment.doctor_name
        );

        setDetailsLoading(false);
    };

    const startCheckin = async (selectedProfessional = null) => {
        setDetailsLoading(true);

        const authToken = await loadAuthToken();

        if (!authToken) {
            setDetailsLoading(false);
            return;
        }

        const ticket = await printPcsTicket(
            authToken.token,
            selectedPrinter[selectedLocation.key]?.address[0].text ??
                selectedLocation.config.pcs_printers?.[0]?.address[0].text ??
                selectedLocation.config.patient_call_system_ip[0].text,
            selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                selectedLocation.config.pcs_printers?.[0]?.certificate[0].text
        );
        if (!ticket) {
            setDetailsLoading(false);
            return;
        }

        useTodaysAppointmentsStore.setState({
            appointmentTicketNumber: {
                ...appointmentTicketNumber,
                [selectedAppointment.id]: ticket,
            },
        });

        if (userGroups?.admin) {
            usePowerUserStore.setState({
                lastUsedCert:
                    selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.certificate[0].text,
            });
        }

        await updateCheckin(AppointmentStatus.ARRIVED, ticket, selectedProfessional);

        const isManualCheckin = !(
            selectedAppointment.data?.patient_name || patients[selectedAppointment.user_id]?.name
        );
        const selectedForms = useTodaysAppointmentsStore.getState().selectedForms[selectedAppointment.id];

        if (!featureAccess[FeatureKey.CONSENT_FORMS] || !selectedForms || selectedForms.length === 0) {
            const responseStatus = await updatePcs(
                authToken.token,
                selectedPrinter[selectedLocation.key]?.address[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.address[0].text ??
                    selectedLocation.config.patient_call_system_ip[0].text,
                selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.certificate[0].text,
                professionalMappings[selectedAppointment.professional.key]?.pcs_stack_id || 'zimmer01',
                ticket
            );

            if (!responseStatus || responseStatus < 200 || responseStatus >= 300) {
                setDetailsLoading(false);
                return;
            }

            await updateCheckin(
                isManualCheckin ? AppointmentStatus.MANUALLY_CHECKED_IN : AppointmentStatus.CHECKED_IN,
                ticket,
                selectedProfessional
            );

            setDetailsLoading(false);
            return;
        }

        const generateCodeBody = prepareGenerateCodeBody(selectedForms);

        const generatedCode = await generateCode(userToken, generateCodeBody);
        await updateCheckin(AppointmentStatus.ARRIVED, ticket, selectedProfessional);

        useTodaysAppointmentsStore.setState({ code: generatedCode });
        setDetailsLoading(false);
    };

    const manualCheckin = async (selectedProfessional) => {
        if (!selectedProfessional || !manualCheckinPatientName) {
            return;
        }
        await startCheckin(selectedProfessional);
    };

    const startLegacyCheckin = async () => {
        setDetailsLoading(true);
        const ticket = selectedLocation?.config.is_patient_call_system_active ? null : uuidv4();

        const isManualCheckin = !(
            selectedAppointment.data?.patient_name || patients[selectedAppointment.user_id]?.name
        );
        const selectedForms = useTodaysAppointmentsStore.getState().selectedForms[selectedAppointment.id];

        if (!featureAccess[FeatureKey.CONSENT_FORMS] || !selectedForms || selectedForms.length === 0) {
            await updateCheckin(
                isManualCheckin ? AppointmentStatus.MANUALLY_CHECKED_IN : AppointmentStatus.CHECKED_IN,
                ticket
            );
            setDetailsLoading(false);
            return;
        }

        const generateCodeBody = prepareGenerateCodeBody(selectedForms);

        await updateCheckin(AppointmentStatus.ARRIVED, ticket);
        const generatedCode = await generateCode(userToken, generateCodeBody);
        useTodaysAppointmentsStore.setState({ code: generatedCode });
        setDetailsLoading(false);
    };

    const globalManualCheckin = async (selectedProfessional, appointmentId) => {
        console.log('[START] Manual Auto Checkin');
        setLoading(true);
        const selectedForms = useTodaysAppointmentsStore.getState().selectedForms[appointmentId];
        const { usePcsForGlobalManualCheckin } = useTodaysAppointmentsStore.getState();

        let ticket = selectedLocation?.config.is_patient_call_system_active ? null : uuidv4();
        let authToken = null;
        console.log(`ticket: ${ticket}`);
        console.log(`isPcsUsed: ${usePcsForGlobalManualCheckin}`);
        if (usePcsForGlobalManualCheckin) {
            console.log('[START] Load Auth Token');
            authToken = await loadAuthToken();
            console.log('[END] Load Auth Token');

            if (authToken) {
                console.log('[START] Print Tickt');
                ticket = await printPcsTicket(
                    authToken.token,
                    selectedPrinter[selectedLocation.key]?.address[0].text ??
                        selectedLocation.config.pcs_printers?.[0]?.address[0].text ??
                        selectedLocation.config.patient_call_system_ip[0].text,
                    selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                        selectedLocation.config.pcs_printers?.[0]?.certificate[0].text
                );
                console.log('[END] Print Tickt');
                console.log(`new ticket number: ${ticket}`);

                if (ticket) {
                    useTodaysAppointmentsStore.setState({
                        appointmentTicketNumber: {
                            ...appointmentTicketNumber,
                            [appointmentId]: ticket,
                        },
                    });
                    console.log('Saved ticket.');
                }
            }
        }

        if (!ticket && !appointmentTicketNumber.global) {
            setLoading(false);
            console.log('Checkin Failed.');
            return;
        }

        const data = {
            group: selectedLocation.key,
            number: ticket ?? appointmentTicketNumber.global,
            name: manualCheckinPatientName,
            user_id: '-',
            location_key: selectedLocation.key,
            user_status: UserStatus.UNKNOWN,
            appointment: {
                id: appointmentId,
                checkin_status:
                    !featureAccess[FeatureKey.CONSENT_FORMS] || !selectedForms || selectedForms.length === 0
                        ? AppointmentStatus.MANUALLY_CHECKED_IN
                        : AppointmentStatus.ARRIVED,
                doctor_name: selectedProfessional ?? '',
            },
            instance_id: selectedLocation.instanceId,
        };

        const response = await updateUserByNumber(userToken, data);
        if (!response.data) {
            setLoading(false);
            return;
        }

        if (featureAccess[FeatureKey.CONSENT_FORMS] && selectedForms?.length > 0) {
            const generateCodeBody = prepareGenerateCodeBody(selectedForms, appointmentId, selectedProfessional);

            const generatedCode = await generateCode(userToken, generateCodeBody);
            useTodaysAppointmentsStore.setState({ code: generatedCode });
        } else if (usePcsForGlobalManualCheckin && authToken) {
            await updatePcs(
                authToken.token,
                selectedPrinter[selectedLocation.key]?.address[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.address[0].text ??
                    selectedLocation.config.patient_call_system_ip[0].text,
                selectedPrinter[selectedLocation.key]?.certificate[0].text ??
                    selectedLocation.config.pcs_printers?.[0]?.certificate[0].text,
                professionalMappings[selectedProfessional]?.pcs_stack_id || 'zimmer01',
                ticket ?? appointmentTicketNumber.global
            );
        }

        const appointmentObj = {
            ...response.data,
            id: response.data.appointment_id,
        };

        const newCheckinData = { ...useTodaysAppointmentsStore.getState().checkinData };
        newCheckinData[response.data.appointment_id] = appointmentObj;

        useTodaysAppointmentsStore.setState({
            checkinData: newCheckinData,
            unknownAppointments: [...unknownAppointments, appointmentObj],
        });
        setLoading(false);

        updateSelectedAppointment(appointmentObj);

        console.log('[END] Manual Auto Checkin');
    };

    const loadUnknownAppointments = async () => {
        const data = await getUnknownAppointments(userToken, selectedLocation.key);
        if (!data) {
            useTodaysAppointmentsStore.setState({ unknownAppointments: [] });
            return;
        }

        useTodaysAppointmentsStore.setState({
            unknownAppointments: data.map((entry) => {
                return {
                    ...entry,
                    id: entry.appointment_id,
                };
            }),
        });
        const newCheckinData = { ...useTodaysAppointmentsStore.getState().checkinData };
        data.forEach((entry) => {
            newCheckinData[entry.appointment_id] = entry;
        });
        useTodaysAppointmentsStore.setState({ checkinData: newCheckinData });
    };

    const loadProfessionalFormTemplates = async (mappings) => {
        const formTemplatePromises = Object.values(mappings).map((professionalMapping) =>
            getFormTemplates(userToken, professionalMapping.user_id).then((result) => ({
                prismicKey: professionalMapping.prismic_key,
                data: result.data?.data ?? [],
            }))
        );

        const resolvedPromises = await Promise.all(formTemplatePromises);

        const formTemplatesObj = resolvedPromises.reduce((obj, professionalData) => {
            if (!professionalData.prismicKey) return obj;

            // eslint-disable-next-line no-param-reassign
            obj[professionalData.prismicKey] = professionalData.data.reduce((accumulator, template) => {
                // eslint-disable-next-line no-param-reassign
                accumulator[template.id] = template;
                return accumulator;
            }, {});

            return obj;
        }, {});

        useTodaysAppointmentsStore.setState({
            professionalFormTemplates: formTemplatesObj,
        });
    };

    const loadProfessionalData = async () => {
        const response = await getUserMappings();
        if (!response.data?.data) return;

        const professionalMappingsObj = response.data.data.reduce((obj, mapping) => {
            if (!mapping.prismic_key) return obj;
            // eslint-disable-next-line no-param-reassign
            obj[mapping.prismic_key] = mapping;
            return obj;
        }, {});

        useTodaysAppointmentsStore.setState({
            professionalMappings: professionalMappingsObj,
        });
    };

    const loadTodaysAppointments = () => {
        const currentDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`;

        getAllTodaysAppointments(userToken, currentDate, selectedLocation.instanceId);
    };

    const loadPatients = async () => {
        const response = await getPatients(userToken);
        if (response?.status === 200) {
            const patientsObj = response.data?.data?.reduce((obj, patient) => {
                if (!patient.id) return obj;
                // eslint-disable-next-line no-param-reassign
                obj[patient.id] = patient;
                return obj;
            }, {});

            useTodaysAppointmentsStore.setState({ patients: patientsObj, isPatientDataLoading: false });
        }
    };

    const loadCheckinData = async () => {
        try {
            const response = await generateAppointmentCheckinData(userToken, selectedLocation.instanceId);

            const checkinDataObj = response.reduce((obj, data) => {
                if (!data.appointment?.id) return obj;
                // eslint-disable-next-line no-param-reassign
                obj[data.appointment.id] = data;
                return obj;
            }, {});

            useTodaysAppointmentsStore.setState({
                checkinData: { ...useTodaysAppointmentsStore.getState().checkinData, ...checkinDataObj },
                isCheckinDataLoading: false,
            });
        } catch (e) {
            if (e.status !== 404) {
                console.debug(e);
                return;
            }
            useTodaysAppointmentsStore.setState({
                isCheckinDataLoading: false,
            });
        }
    };

    const resetProfessionalsFilter = () => {
        setProfessionalsFilter(
            Object.keys(professionals)
                .filter(
                    (key) =>
                        professionalMappings[key]?.dc_instance_id === selectedLocation.instanceId &&
                        professionals[key].location_hub.id === selectedLocation.config.hub.id
                )
                .reduce((obj, key) => {
                    // eslint-disable-next-line no-param-reassign
                    obj[key] = true;
                    return obj;
                }, {})
        );
    };

    const applyFilters = (appointments) => {
        let newFilteredAppointments = [...appointments];
        if (!showPastAppointments) {
            newFilteredAppointments = newFilteredAppointments.filter((appointment) => !appointment.inactive);
        }
        if (!showAppointmentsWithoutProfessional) {
            newFilteredAppointments = newFilteredAppointments.filter((appointment) => appointment.professional.key);
        }

        // Filter by professionalsFilter
        if (selectedLocation.config.is_filtering_active) {
            newFilteredAppointments = newFilteredAppointments.filter(
                (appointment) => professionalsFilter[appointment.professional.key]
            );
        }

        if (showUnknownAppointments) {
            const filteredUnknownAppointments = unknownAppointments.filter((entry) => {
                const isCorrectLocation = entry.location_key === selectedLocation.key;
                const isInProfessionalsFilterObj = selectedLocation.config.is_filtering_active
                    ? professionalsFilter[entry.appointment.doctor_name]
                    : true;
                return isCorrectLocation && isInProfessionalsFilterObj;
            });

            newFilteredAppointments.push(...filteredUnknownAppointments);

            newFilteredAppointments.sort(
                (a, b) =>
                    new Date(a.start ?? a.appointment?.checkin_time) - new Date(b.start ?? b.appointment?.checkin_time)
            );
        }
        return newFilteredAppointments;
    };

    useEffect(() => {
        if (professionals && professionalMappings) resetProfessionalsFilter();
    }, [professionals, professionalMappings, selectedLocation]);

    useEffect(() => {
        if (!syncUserInput) {
            setFilteredSyncSearch(Object.values(syncSearchUserData));
            return;
        }

        const newFilteredSearch = Object.values(syncSearchUserData).filter(
            (entry) =>
                (entry.name?.toLowerCase().includes(syncUserInput.toLowerCase()) ||
                    entry.user_id.includes(syncUserInput.toLowerCase())) &&
                entry.user_id !== selectedSyncUser
        );

        if (
            newFilteredSearch.length === 1 &&
            (!newFilteredSearch[0].name || newFilteredSearch[0].name?.toLowerCase() === syncUserInput.toLowerCase())
        ) {
            setSelectedSyncUser(newFilteredSearch[0].user_id);
        } else if (newFilteredSearch.length === 0) {
            setSelectedSyncUser(syncUserInput);
        }

        setFilteredSyncSearch(newFilteredSearch);
    }, [syncUserInput, syncSearchUserData]);

    useEffect(() => {
        useLocationStore.setState({ loading: true });
        if (!savedTodaysAppointments && selectedLocation) loadTodaysAppointments();
        if (!patients) loadPatients();
        if (!professionalMappings) loadProfessionalData();
        if (!unknownAppointments && selectedLocation) loadUnknownAppointments();
    }, []);

    useEffect(() => {
        if (professionalMappings) loadProfessionalFormTemplates(professionalMappings);
        setSelectedAppointment(null);
    }, [currentCustomer, professionalMappings]);

    useEffect(() => {
        if (loading || !savedTodaysAppointments) {
            return;
        }
        useTodaysAppointmentsStore.setState({
            todaysAppointments: undefined,
            isCheckinDataLoading: true,
            isTodaysAppointmentsLoading: true,
        });
        setLoading(true);
        setSelectedAppointment(null);
        setToDefault();
        loadTodaysAppointments();
        loadUnknownAppointments();
        useLocationStore.setState({ loading: true });
    }, [selectedLocation]);

    useEffect(() => {
        const setData = async () => {
            if (!successMessage || !professionals || !professionalMappings || !prismicData || !unknownAppointments) {
                return;
            }

            loadCheckinData();

            const sortedAppointments = [...todaysAppointments].sort((a, b) => new Date(a.start) - new Date(b.start));

            const syncSearchObj = sortedAppointments
                .filter((appointment) => appointment.links.patient_id && appointment.links.patient_id !== '-')
                .reduce((obj, appointment) => {
                    if (obj[appointment.links.patient_id]) return obj;

                    // eslint-disable-next-line no-param-reassign
                    obj[appointment.links.patient_id] = {
                        user_id: appointment.links.patient_id,
                        name: appointment.data.patient_name,
                    };

                    return obj;
                }, {});

            useTodaysAppointmentsStore.setState({
                todaysAppointments: sortedAppointments,
                syncSearchUserData: syncSearchObj,
            });
            useLocationStore.setState({ loading: false });
            setLoading(false);
            setSearch('');
            setFilteredAppointments(applyFilters(sortedAppointments));
        };

        setData();
    }, [successMessage, professionals, patients, professionalMappings, prismicData, unknownAppointments]);

    useEffect(() => {
        if (error) {
            useTodaysAppointmentsStore.setState({ hasFailed: true });
            setLoading(false);
        }
    }, [error]);

    const reloadData = async () => {
        setLoading(true);
        setSelectedAppointment(null);
        setToDefault();
        loadTodaysAppointments();
    };

    useEffect(() => {
        if (!savedTodaysAppointments) return;

        const newFilteredAppointments = applyFilters(savedTodaysAppointments);

        if (search) {
            setFilteredAppointments(
                newFilteredAppointments.filter((item) => {
                    let includesSearch = true;
                    search.split(' ').forEach((value) => {
                        if (
                            !(
                                (item.user_id &&
                                    patients[item.user_id]?.name.first
                                        .concat(patients[item.user_id]?.name.last)
                                        .toLowerCase()
                                        .includes(value.toLowerCase())) ||
                                item.data?.patient_name?.toLowerCase().includes(value.toLowerCase()) ||
                                item.name?.toLowerCase().includes(value.toLowerCase()) ||
                                (item.professional?.key &&
                                    professionals[item.professional.key]?.display_name[0].text
                                        .toLowerCase()
                                        .includes(value.toLowerCase())) ||
                                (item.appointment?.doctor_name &&
                                    professionals[item.appointment.doctor_name]?.display_name[0].text
                                        .toLowerCase()
                                        .includes(value.toLowerCase()))
                            )
                        )
                            includesSearch = false;
                    });
                    return includesSearch;
                })
            );
        } else setFilteredAppointments(newFilteredAppointments);
    }, [search, showPastAppointments, showAppointmentsWithoutProfessional, unknownAppointments, professionalsFilter]);

    if (loading) return <LoadingPage />;

    if (hasFailed) return <FailedFallback reloadData={reloadData} />;

    return (
        <CContainer>
            <CContainer
                className="m-0 p-0"
                style={{
                    backdropFilter: 'blur(0.3rem)',
                    zIndex: 5,
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <CForm className="d-flex align-items-center gap-4">
                        <CFormInput
                            style={{ width: '12rem' }}
                            type="text"
                            id="search"
                            value={search}
                            placeholder={t('Search')}
                            onChange={(e) => setSearch(e.target.value)}
                            disabled={detailsLoading}
                        />
                        {selectedLocation.config.is_filtering_active && filteredAndSortedProfessionals.length > 1 && (
                            <div style={{ position: 'relative' }}>
                                <CButton color="secondary" onClick={() => setShowFilterSection(!showFilterSection)}>
                                    <CIcon icon={cilFilter} size="md" />
                                </CButton>
                                {showFilterSection && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '50rem',
                                            top: 0,
                                            left: '50%',
                                            transform: 'translate(-25%, 3rem)',
                                        }}
                                    >
                                        <CCard className="mb-3 d-flex align-items-center justify-content-start gap-3 p-0 w-50">
                                            <CCardBody className="d-flex align-items-start w-100 flex-column user-select-none">
                                                <h5 className="m-0 p-0 mb-2">Filter Einstellungen</h5>
                                                <p className="m-0 p-0">Behandler:innen</p>
                                                {filteredAndSortedProfessionals.map((key) => (
                                                    <div key={key} onClick={() => updateProfessionalsFilter(key)}>
                                                        {/* this div wraps the form check to allow clicking in the label as well */}
                                                        <CFormCheck
                                                            label={professionals[key].display_name[0].text}
                                                            checked={professionalsFilter[key]}
                                                            className="form-check-dark text-black"
                                                        />
                                                    </div>
                                                ))}
                                                <div className="w-100 d-flex align-items-center justify-content-between mt-3">
                                                    <CButton
                                                        style={{ width: '7rem' }}
                                                        size="sm"
                                                        color="light"
                                                        className="border-secondary border-2"
                                                        onClick={resetProfessionalsFilter}
                                                    >
                                                        Zurücksetzen
                                                    </CButton>
                                                    <CButton
                                                        style={{ width: '7rem' }}
                                                        size="sm"
                                                        color="secondary"
                                                        onClick={() => setShowFilterSection(false)}
                                                    >
                                                        Fertig
                                                    </CButton>
                                                </div>
                                            </CCardBody>
                                        </CCard>
                                    </div>
                                )}
                            </div>
                        )}
                        <CFormCheck
                            label={t('Past Appointments (today)')}
                            checked={showPastAppointments}
                            onChange={() => setShowPastAppointments(!showPastAppointments)}
                            disabled={detailsLoading}
                            className="form-check-dark text-black"
                        />
                        <CFormCheck
                            label="Termine ohne Professional"
                            checked={showAppointmentsWithoutProfessional}
                            onChange={() =>
                                setShowAppointmentsWithoutProfessional(!showAppointmentsWithoutProfessional)
                            }
                            disabled={detailsLoading}
                            className="form-check-warning"
                        />
                    </CForm>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <CTooltip content="Benutzer synchronisieren">
                            <CButton
                                style={{ height: '38px' }}
                                className="d-flex align-items-center justify-content-center"
                                color="primary"
                                onClick={() => {
                                    setShowSyncSection(!showSyncSection);
                                    setSyncUserInput('');
                                }}
                            >
                                <CIcon size="lg" icon={cilUser} />
                                <CIcon size="sm" icon={cilReload} />
                            </CButton>
                        </CTooltip>
                        {selectedLocation.config.is_new_checkin_available && (
                            <PrinterDropdown>
                                <CButton
                                    onClick={() =>
                                        useTodaysAppointmentsStore.setState({
                                            showGlobalManualCheckinModal: true,
                                            usePcsForGlobalManualCheckin: true,
                                        })
                                    }
                                    color="secondary"
                                >
                                    Manueller Auto-Check-In
                                </CButton>
                            </PrinterDropdown>
                        )}
                        <CButton
                            onClick={() =>
                                useTodaysAppointmentsStore.setState({
                                    showGlobalManualCheckinModal: true,
                                    usePcsForGlobalManualCheckin: false,
                                })
                            }
                            color="light"
                            className="border-secondary border-2"
                        >
                            Manueller Check-In
                        </CButton>
                    </div>
                </div>
                <Alert />
                <div className="mt-3">
                    <CCollapse visible={showSyncSection}>
                        <div className="mb-3 d-flex align-items-center justify-content-end gap-3 p-0">
                            {syncLoading && (
                                <div>
                                    <EternoSpinner />
                                </div>
                            )}
                            {!syncLoading && (
                                <>
                                    <div
                                        style={{
                                            width: '25%',
                                            position: 'relative',
                                        }}
                                    >
                                        <CFormInput
                                            ref={inputRef}
                                            value={syncUserInput}
                                            onChange={(e) => setSyncUserInput(e.target.value)}
                                            placeholder="Patient"
                                            onFocus={() => setShowSyncSearchResults(true)}
                                            onBlur={() => {
                                                if (!isListBeingClicked) setShowSyncSearchResults(false);
                                            }}
                                        />
                                        {showSyncSearchResults &&
                                            ((syncUserInput && Object.values(filteredSyncSearch)?.length > 0) ||
                                                selectedSyncUser) && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        background: 'white',
                                                        border: '1px solid #ccc',
                                                        width: '100%',
                                                        top: '100%',
                                                    }}
                                                >
                                                    {selectedSyncUser && syncSearchUserData[selectedSyncUser] && (
                                                        <>
                                                            <div
                                                                style={{
                                                                    padding: '0.5rem',
                                                                    cursor: 'pointer',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    marginBottom:
                                                                        syncUserInput &&
                                                                        Object.values(filteredSyncSearch)?.length > 0
                                                                            ? '1rem'
                                                                            : '0',
                                                                }}
                                                                className="bg-primary text-white"
                                                            >
                                                                <span style={{ fontSize: '0.8rem' }}>
                                                                    {syncSearchUserData[selectedSyncUser].name}
                                                                </span>
                                                                <span
                                                                    style={{ fontSize: '0.5rem' }}
                                                                >{`(${syncSearchUserData[selectedSyncUser].user_id})`}</span>
                                                            </div>
                                                            {syncUserInput &&
                                                                Object.values(filteredSyncSearch)?.length > 0 && (
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '1px',
                                                                            background: 'black',
                                                                        }}
                                                                    />
                                                                )}
                                                        </>
                                                    )}
                                                    {syncUserInput &&
                                                        Object.values(filteredSyncSearch).map((user) => (
                                                            <div
                                                                style={{
                                                                    padding: '0.5rem',
                                                                    cursor: 'pointer',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                                className="bg-white text-black"
                                                                onClick={() => {
                                                                    setSelectedSyncUser(user.user_id);
                                                                }}
                                                                onMouseDown={() => {
                                                                    setIsListBeingClicked(true);
                                                                }}
                                                                onMouseUp={() => {
                                                                    setIsListBeingClicked(false);
                                                                    inputRef.current.focus();
                                                                }}
                                                                key={user.user_id}
                                                            >
                                                                <span style={{ fontSize: '0.8rem' }}>{user.name}</span>
                                                                <span
                                                                    style={{ fontSize: '0.5rem' }}
                                                                >{`(${user.user_id})`}</span>
                                                                {selectedSyncUser === user.user_id && (
                                                                    <CIcon icon={cilCheckAlt} />
                                                                )}
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                    </div>
                                    <CButton color="primary" onClick={syncPatient}>
                                        Sync
                                    </CButton>
                                </>
                            )}
                        </div>
                    </CCollapse>
                </div>
            </CContainer>
            <div
                style={{
                    display: 'flex',
                    height: '80vh',
                }}
            >
                <TableContainer
                    component={Paper}
                    style={{
                        width: selectedAppointment ? '60%' : '100%',
                        transition: 'width 0.15s ease',
                        borderRight: '1px solid #ccc',
                    }}
                >
                    <Table aria-label="collapsible table">
                        <TableHead
                            style={{
                                background: 'white',
                            }}
                        >
                            <TableRow>
                                <TableCell>
                                    <b>{t('Time')}</b>
                                </TableCell>
                                <TableCell style={{ minWidth: '11rem' }}>
                                    <b>{t('Patient name')}</b>
                                </TableCell>
                                <TableCell>
                                    <b>{t('Professional')}</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b>{t('Status')}</b>
                                </TableCell>
                                <TableCell>
                                    <b />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {filteredAppointments.map((item) => (
                            <AppointmentEntry
                                key={item.id}
                                appointment={item}
                                patientName={item.user_id && patients?.[item.user_id]?.name}
                                selectedAppointment={selectedAppointment}
                                updateSelectedAppointment={updateSelectedAppointment}
                                detailsLoading={detailsLoading}
                                professionalName={
                                    professionals[item.professional?.key ?? item.appointment?.doctor_name]
                                        ?.display_name[0].text ||
                                    professionals[checkinData[item.id]?.appointment?.doctor_name]?.display_name[0].text
                                }
                            />
                        ))}
                    </Table>
                </TableContainer>
                {selectedAppointment && (
                    <AppointmentDetails
                        appointment={selectedAppointment}
                        detailsLoading={detailsLoading}
                        setDetailsLoading={setDetailsLoading}
                        patientName={selectedAppointment.user_id && patients[selectedAppointment.user_id]?.name}
                        professionalName={
                            professionals[
                                selectedAppointment.professional?.key || selectedAppointment.appointment?.doctor_name
                            ]?.display_name[0].text ||
                            professionals[checkinData[selectedAppointment.id]?.appointment?.doctor_name]
                                ?.display_name[0].text
                        }
                        showNewCheckin={selectedLocation.config?.is_new_checkin_available}
                        showLegacyCheckin={selectedLocation.config?.is_legacy_checkin_available}
                        checkin={startCheckin}
                        confirmCheckin={confirmCheckin}
                        updateCheckin={updateCheckin}
                        startLegacyCheckin={startLegacyCheckin}
                    />
                )}
            </div>

            <LegacyCheckinModal
                startCheckin={startLegacyCheckin}
                showLegacyCheckinModal={showLegacyCheckinModal}
                appointment={selectedAppointment}
            />

            <LegacyManualCheckinModal
                selectedAppointment={selectedAppointment}
                showLegacyManualCheckinModal={showLegacyManualCheckinModal}
                manualCheckinPatientName={manualCheckinPatientName}
                setManualCheckinPatientName={setManualCheckinPatientName}
                startCheckin={startLegacyCheckin}
                appointment={selectedAppointment}
            />

            <ManualCheckinModal
                selectedAppointment={selectedAppointment}
                showNewManualCheckinModal={showNewManualCheckinModal}
                manualCheckinPatientName={manualCheckinPatientName}
                setManualCheckinPatientName={setManualCheckinPatientName}
                manualCheckin={manualCheckin}
            />

            <GlobalManualCheckinModal
                showGlobalManualCheckinModal={showGlobalManualCheckinModal}
                manualCheckinPatientName={manualCheckinPatientName}
                setManualCheckinPatientName={setManualCheckinPatientName}
                globalManualCheckin={globalManualCheckin}
            />
            <CertModal />
            <AppointmentCodeModal />
            <LoadingIndicator />
        </CContainer>
    );
};

export default TodaysAppointments;
