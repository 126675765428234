import axios from 'axios';

import { UserStatus } from '@/utils/constants.js';
import { dayjs } from '@/utils/dayjsSetup.js';
import { getCustomerId } from '@/utils/helpers';

const BASE_URL = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PATIENT_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_ADMIN_API_PATH
}/`;

export async function getTodayAppointments(userToken, currentDate, selectedLocation) {
    const config = {
        headers: {
            Authorization: userToken,
            version: 'v2',
            customer_id: getCustomerId(),
        },
    };
    try {
        console.debug(`${BASE_URL}user/appointments/?day=${currentDate}`);
        // const ownDate = '2022-11-24'
        const res = await axios.get(`${BASE_URL}user/appointments/today?instance_id=${selectedLocation}`, config);
        console.debug('res in appointment call');
        console.debug(res);
        return res;
    } catch (error) {
        return error;
    }
}

export async function getAppointments(userToken) {
    const config = {
        headers: {
            Authorization: userToken,
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.get(`${BASE_URL}user/appointments`, config);
        return res;
    } catch (error) {
        return error;
    }
}

export async function updateUserByNumber(userToken, userInfo) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.post(`${BASE_URL}call-system/users`, userInfo, config);
        console.debug(res);
        return res;
    } catch (error) {
        return error;
    }
}

export async function generateCode(userToken, codeData) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.post(`${BASE_URL}user/form-data/codes/generate`, codeData, config);
        return res?.data?.code;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function saveSelectedFormsForAppointment(userToken, saveDataInfo) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.post(`${BASE_URL}user/form-data/appointment`, saveDataInfo, config);
        return res;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function getSelectedFormsForAppointment(userToken, userId, appointmentId) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.get(
            `${BASE_URL}user/form-data/appointment?user_id=${userId}&appointment_id=${appointmentId}`,
            config
        );
        return res?.data?.data?.forms;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function generateAppointmentCheckinData(userToken, instanceId) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
        params: {
            instance_id: instanceId,
            day: dayjs().format('YYYY-MM-DD'),
        },
    };
    const response = await axios.get(`${BASE_URL}call-system/users/day`, config);

    const records = response.data.filter((entry) => entry.user_status === UserStatus.KNOWN);
    return records;
}

export async function getSharedForms(userToken, user_id) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.get(`${BASE_URL}user/form-data/documents?user_id=${user_id}`, config);
        return res?.data?.data;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function getRequiredFormsForAppointment(
    userToken,
    userId,
    professionalId,
    insuranceType,
    appointmentTypeKeys
) {
    const config = {
        headers: {
            Authorization: userToken,
            'Content-Type': 'application/json',
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await axios.get(
            `${BASE_URL}user/form-data/required-templates?user_id=${userId}&professional_id=${professionalId}&insurance_type=${insuranceType}&appointment_type_keys=${appointmentTypeKeys}`,
            config
        );
        return res?.data?.templates;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}
